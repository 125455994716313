import { API } from "aws-amplify";

export const addPartnerAccount = async ({
  ABN,
  addT2Cert,
  email,
  entityAddressStreet,
  entityName,
  entityType,
  firstName,
  isActive,
  isSetup,
  isTypeCAP,
  isTypeConsultant,
  isTypeMSP,
  isTypeMembership,
  isTypeReferrer,
  isTypeSupplyChain,
  isTypeFranchise,
  lastName,
  minimumVolume,
  name,
  phone,
  role,
  subscriptionDiscount,
  volumeDiscount,
  website,
  addPartnerConsultantBronzeQTY,
  addPartnerConsultantGoldQTY,
  addPartnerConsultantSilverQTY,
  addPartnerFranchiseBronzeQTY,
  addPartnerFranchiseGoldQTY,
  addPartnerFranchiseSilverQTY,
  addPartnerMSPBronzeQTY,
  addPartnerMSPGoldQTY,
  addPartnerMSPSilverQTY,
  addPartnerMembershipBronzeQTY,
  addPartnerMembershipGoldQTY,
  addPartnerMembershipSilverQTY,
  addPartnerReferrerBronzeQTY,
  addPartnerReferrerGoldQTY,
  addPartnerReferrerSilverQTY,
  addPartnerSupplyChainBronzeQTY,
  addPartnerSupplyChainGoldQTY,
  addPartnerSupplyChainSilverQTY,
}) => {
  const myInit = {
    body: {
      ABN,
      addT2Cert,
      email,
      entityAddressStreet,
      entityName,
      entityType,
      firstName,
      isActive,
      isSetup,
      isTypeCAP,
      isTypeConsultant,
      isTypeMSP,
      isTypeMembership,
      isTypeReferrer,
      isTypeSupplyChain,
      isTypeFranchise,
      lastName,
      minimumVolume,
      name,
      phone,
      role,
      subscriptionDiscount,
      volumeDiscount,
      website,
      addPartnerConsultantBronzeQTY,
      addPartnerConsultantGoldQTY,
      addPartnerConsultantSilverQTY,
      addPartnerFranchiseBronzeQTY,
      addPartnerFranchiseGoldQTY,
      addPartnerFranchiseSilverQTY,
      addPartnerMSPBronzeQTY,
      addPartnerMSPGoldQTY,
      addPartnerMSPSilverQTY,
      addPartnerMembershipBronzeQTY,
      addPartnerMembershipGoldQTY,
      addPartnerMembershipSilverQTY,
      addPartnerReferrerBronzeQTY,
      addPartnerReferrerGoldQTY,
      addPartnerReferrerSilverQTY,
      addPartnerSupplyChainBronzeQTY,
      addPartnerSupplyChainGoldQTY,
      addPartnerSupplyChainSilverQTY,
    },
  };
  const result = await API.post("apiAdmin", "/partner/add", myInit);
  console.log(result);
  return result;
};
