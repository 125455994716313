import { ROUTE } from "variables";
import { useAppSessionState } from "middleware";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "services";

export function RequireAuth({ children }) {
  const { appSession, setSession } = useAppSessionState();
  const location = useLocation();
  const navigate = useNavigate();

  const checkAccountState = async () => {
    const sess = await AuthService.checkLoginState();
    setSession(sess);
    if (!sess) {
      navigate({
        pathname: ROUTE.LOGIN,
        search: location.search,
      });
    } else {
      if (!sess.group.includes("admin")) {
        signout();
      }
      const user = await AuthService.getCurrentAuthSession();
      // if (user.preferredMFA === "NOMFA") {
      //   setupMFA();
      // }
    }
  };

  const setupMFA = () => {
    navigate({
      pathname: ROUTE.MFA_SETUP,
      search: location.search,
    });
  };

  const signout = () => {
    AuthService.signOut().then(() => {
      navigate({
        pathname: ROUTE.LOGIN,
        search: location.search,
      });
    });
  };

  useEffect(() => {
    checkAccountState();
  }, []);

  return <>{!!appSession.session && children}</>;
}
