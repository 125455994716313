import { generateCertification } from "api";
import { spinner } from "assets";
import { FormInput, Spinner } from "components";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ROUTE } from "variables";

export function CreateCertification({ ABN }) {
  // Temporary functions to create certification
  const [show, setShow] = useState(false);
  const [certInput, setCertInput] = useState({
    level: 2,
    standard: "2025",
  });
  const [loading, setLoading] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCreate = async () => {
    setLoading(true);
    await generateCertification({
      ABN,
      level: certInput.level,
      standard: certInput.standard,
    });
    window.location.href = `${ROUTE.ROOT}?ABN=${ABN}`;
    setLoading(false);
  };
  const handleInput = (event) =>
    setCertInput({
      ...certInput,
      [event.target.name]: event.target.value,
    });

  return (
    <div>
      <Button variant="btn btn-admin-settings" onClick={handleShow}>
        Create certification
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create certification</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormInput
            controlId="level"
            options={[1, 2, 3, 4, 5]}
            label="Level"
            type="select"
            name="level"
            value={2}
            onChange={handleInput}
            required
          />
          <FormInput
            controlId="standard"
            options={["2023", "2025"]}
            label="Standard"
            type="select"
            name="standard"
            value={"2025"}
            onChange={handleInput}
            required
          />

          <FormInput
            controlId="certABN"
            label="ABN"
            type="text"
            name="abn"
            value={ABN}
            disabled
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={handleCreate}>
            Create
          </Button>
        </Modal.Footer>

        {loading && (
          <div className="overlay">
            <Spinner jsonTemplate={spinner} />
          </div>
        )}
      </Modal>
    </div>
  );
}
